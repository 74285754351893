// import React, { useEffect, useRef, useState } from "react";
// import { Helmet } from "react-helmet";
// import Layout from "../../components/layout";
// import { AiOutlineWarning } from "react-icons/ai";
// import SEO from "../../components/seo.js";
// import BusinessCrumbs from "../../components/Home/BusinessCrumbs.js";
// import { useForm } from "react-hook-form";
// import SvgAnimation from "../../components/SvgAnimate.js";

// const postData = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({ success: true });
//     }, 100);
//   });
// };

// const ProtectCloud = () => {
//   // const { buttonLabel, className } = props;
//   const [modal, setModal] = useState(false);

//   const toggleModal = () => setModal(!modal);
//   const [activeTab, setActiveTab] = useState("1");
//   const toggle = (tab) => {
//     if (activeTab !== tab) setActiveTab(tab);
//   };
//   const [collapsed, setCollapsed] = useState(true);

//   const toggleNavbar = () => setCollapsed(!collapsed);

//   const [isOpen, setNav] = useState(true);
//   const toggleTab = () => {
//     setNav((isOpen) => !isOpen);
//   };
//   const onTop = () => {
//     window.scroll(0, 0);
//   };

//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors, isSubmitting },
//   } = useForm();

//   const [hideAlert, SetHideAlert] = useState(true);
//   const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
//   const msgAlrt = useRef(null);
//   useEffect(() => {
//     setTimeout((hideAlert) => {
//       SetHideAlert(hideAlert);
//       // SetHideAlert(true);
//       const hdMsg = document.getElementById("msgAlert");
//       // msgAlrt.current.style.display = "none";
//     }, 15000);
//   });

//   const submitForm = async (data) => {
//     console.log("Submission starting", data);
//     const result = await postData(data);
//     console.log("Submitting complete", result.success);
//     setIsSuccessfullySubmitted(result.success);
//     reset();
//     console.log(data);
//   };

//   return (
//     <Layout>
//       <Helmet>
//         <meta
//           name="description"
//           content="Synectiks Secure Cloud Hosted Services supports a variety of workloads & Disaster Recovery with a high degree of control, security and simplicity. Support your mission-critical applications and workloads with instant and scalable infrastructure. With Synectiks Cloud Hosted Services, you can focus on your business needs instead of spending the time and money it takes to plan, procure, secure and manage the right infrastructure to meet your application demands."
//         />
//         <link
//           rel="canonical"
//           href="https://synectiks.com/protect-cloud/index.html"
//         />
//       </Helmet>
//       <SEO title="Protect  Cloud" />
//       <div className="bg-lightgrey">
//         <div className="d-block w-100 py-3 px-md-5">
//           <div className="container-fluid">
//             <BusinessCrumbs
//               title="Home"
//               subtitle="Business Continuity & Cyber Security"
//               pageTitle="Protect Cloud"
//             />
//             <div className="d-flex flex-column flex-md-row mt-4">
//               <div className="Pc-left pe-md-5 mb-3">
//                 <h1>Protect Cloud</h1>
//                 <p>
//                   Protect Cloud is a SaaS based readily available Innovative and
//                   powerful web-based ALL-IN-ONE solution, with one-agent on any
//                   given Server/End-point device to serve all{" "}
//                   <a href="/cloud-backup-service/index.html">Backup</a> &{" "}
//                   <a href="/cloud-disaster-recovery-service/index.html">
//                     Disaster Recovery{" "}
//                   </a>
//                   needs along with basic necessary features like Anti-Virus,
//                   Anti-Malware, Vulnerability Assessment, Patch management,
//                   Remote Diagnostic assistance and URL-filtering. All devices in
//                   your infrastructure are easily managed from a single web
//                   console and well protected to meet all Business continuity,
//                   Security, and compliance requirements.
//                 </p>
//                 <p>
//                   Maintain all the basic security and protection features with a
//                   single solution, and avoid the hassle of purchasing software
//                   from multiple vendors and installing multiple agents on the
//                   same machine. Pick and choose the required license type for
//                   each server/end-point device from the following, mix and match
//                   the licenses as needed for each device in your environment.
//                 </p>
//               </div>
//               <div className="Pc-right w-50">
//                 <p
//                   id="req-font"
//                   className="font-weight-bold text-center mt-3 mb-0"
//                 >
//                   Request for a
//                   <span className="text-black bold">&nbsp;Quick Demo</span>
//                 </p>
//                 <div>
//                   <div className="text-black py-2 py-md-1">
//                     <form
//                       form="Protectform"
//                       id="Protectform"
//                       name="Protectform"
//                       className="was-validated px-0"
//                       action="#"
//                       onSubmit={handleSubmit(submitForm)}
//                     >
//                       <div className="row d-flex flex-column">
//                         <div className="text-left col field-group">
//                           <label htmlFor="name">Your Name *</label>
//                           <input
//                             id="name"
//                             maxlength="80"
//                             size="20"
//                             type="text"
//                             {...register("first_name", {
//                               required: true,
//                               minLength: 2,
//                               MaxLength: 80,
//                             })}
//                             disabled={isSubmitting || isSuccessfullySubmitted}
//                           />
//                           <div className="error">
//                             {errors.first_name && (
//                               <div className="d-flex align-items-baseline">
//                                 <AiOutlineWarning className="mr-2" />
//                                 <p>Enter Your Name</p>
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                         <div className="text-left col field-group">
//                           <label for="email">Email *</label>
//                           <input
//                             id="email"
//                             size="20"
//                             type="text"
//                             {...register("email", {
//                               required: true,
//                               pattern: {
//                                 value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
//                               },
//                             })}
//                             disabled={isSubmitting || isSuccessfullySubmitted}
//                           />
//                           <div className="errorml">
//                             {errors.email && (
//                               <div className="d-flex align-items-baseline">
//                                 <AiOutlineWarning className="mr-2" />
//                                 <p>Enter Your Email Id</p>
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                         <div className="text-center col field-group">
//                           <input
//                             type="submit"
//                             name="submit"
//                             value="Submit"
//                             className="btn btn-primary w-50 pos-3 mx-auto"
//                             id="submit-btn"
//                           />
//                         </div>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {isSuccessfullySubmitted && (
//               <div
//                 ref={msgAlrt}
//                 id="msgAlert"
//                 className="w-100 alert alert-success"
//                 role="alert"
//               >
//                 <div className="text-center">
//                   <h1 className="alert-heading text-capitalize text-center">
//                     We have recieved your request for demo
//                   </h1>
//                   <hr />
//                   <h3 className="text-capitalize text-center">
//                     we will get back <br /> to you soon !
//                   </h3>
//                 </div>
//               </div>
//             )}
//             <div className="mt-2 svg-animation w-60  m-auto">
//               <SvgAnimation />
//             </div>
//             <p className="lineHeight-24 ptext mb-5">
//               Eliminate security breaches, downtime and data loss. Protect Cloud
//               is the only solution that natively integrates cloud security, data
//               protection and management to protect endpoints, systems and data.
//               Integration and automation provide unmatched protection, making it
//               simple to manage all the devices in your environment.
//             </p>
//             <p>
//               Cyber protect Advanced packages breakdown, choose the package you need for your workload
//             </p>
//             <br />
//             <p>
//               <b style={{ fontWeight: "bold" }}>Advanced Management Package:</b>
//             </p>
//             <ul>
//               <li>Vulnerability assessment (Windows/Mac/Linux along with third-party apps)</li>
//               <li>Software & Hardware Inventory</li>
//               <li>Automated patch management</li>
//               <li>ML-based monitoring</li>
//               <li>Software deployment</li>
//               <li>Al-enabled remote scripting</li>
//               <li>Automated pre-patch backup</li>
//               <li>HDD health monitoring</li>
//               <li style={{ whiteSpace: 'nowrap' }}>
//                 Next-gen remote desktop and assistance
//                 <p style={{ fontSize: '2px', display: 'inline', margin: 0 }}>(with NEAR Protocol)</p>
//               </li>

//             </ul>
//             <br />
//             <p>
//               <b style={{ fontWeight: "bold" }}>Advanced Security Package:</b>
//             </p>
//             <ul>
//               <li>Advanced Security + EDR</li>
//               <li>Gen Al-guided incident investigation, analysis, automated response</li>
//               <li>Single-click response, including attack-specific rollback and recovery</li>
//               <li>Next Generation Antivirus (NGAV)</li>
//               <li>Anti-ransomware protection</li>
//               <li>O-day and exploit protection</li>
//               <li>URL filtering</li>
//               <li>Anti-malware scans of backups</li>
//             </ul>

//             <br />
//             <p>
//               <b style={{ fontWeight: "bold" }}>Advanced Data Protection (Backup) Package:</b>
//             </p>
//             <ul>
//               <li>Backup of 25+ workload types (Local & cloud storage)</li>
//               <li>Encryption and deduplication</li>
//               <li>Ransomware-proof backup</li>
//               <li>Group management Advanced Backup</li>
//               <li>One-click mass recovery</li>
//               <li>Continuous data protection</li>
//               <li>Off-host validation, replication, and conversion to VMs</li>
//               <li>Support for different types of application & database level backup</li>
//             </ul>

//             <br />
//             <p>
//               <b style={{ fontWeight: "bold" }}>Advanced Data Protection (Disaster Recovery) Package:</b>
//             </p>
//             <ul>
//               <li>Cloud-only VPN connection</li>
//               <li>Production and test failover to Acronis Cloud</li>
//               <li>Orchestration with runbooks</li>
//               <li>Al-assisted Automated test failover</li>
//               <li>Fast Automated Failback with near-zero downtime</li>
//               <li>Multiple connectivity options:</li>
//               <li>IPsec Multisite VPN, L2 site-to-site open VPN, Cloud-only VPN</li>
//             </ul>

//             <br />
//             <p>
//               <b style={{ fontWeight: "bold" }}>Microsoft 365 & Google Workspace Backup:</b>
//             </p>
//             <ul>
//               <li>Unlimited backup storage for Exchange Online, SharePoint Online, OneDrive, Teams, OneNote</li>
//               <li>Unlimited backup storage Google Workspace</li>
//             </ul>

//             <br />
//             <p>
//               <b style={{ fontWeight: "bold" }}>Advanced Email Security:</b>
//             </p>
//             <ul>
//               <li>Phishing and Quishing prevention</li>
//               <li>Business Email Compromise Prevention</li>
//               <li>Malware protection</li>
//               <li>APT and zero-day protection</li>
//               <li>Account takeover detection</li>
//               <li>Microsoft 365, Google Workspace, Exchange, and any SMTP supporting email service</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <script
//         dangerouslySetInnerHTML={{
//           __html: `
//             var vv_base_id = 'q00IB13hyn';
// var vv_ext_id = '5d53ed9a';
// var __ibaseUrl = (("https:" == document.location.protocol) ? "https://frontend.id-visitors.com" : "http://frontend.id-visitors.com");
// (function () {
// var va = document.createElement('script'); va.type = 'text/javascript'; va.async = true; va.src = __ibaseUrl + '/cscripts/' + vv_base_id + '-' + vv_ext_id + '.js'; var sv = document.getElementsByTagName('script')[0]; sv.parentNode.insertBefore(va, sv); })();
// `,
//         }}
//       />
//       <script
//         dangerouslySetInnerHTML={{
//           __html: `

// var vvfc_BaseURL = (("https:" == document.location.protocol) ? https://forms.id-visitors.com/FrontEndWeb/ : http://forms.id-visitors.com/FrontEndWeb/);

// var vvfc_ServiceURL = vvfc_BaseURL + 'ProcessFormCapture.aspx';

// var vvfc_ScriptURL = vvfc_BaseURL + 'Scripts/vvfcscript.js';

// var trackedForms = ['Protectform'];

// var options = { accountId: 'q00IB13hyn', serviceURL: vvfc_ServiceURL, pollInterval: 5000,

// forms: [{ id: 'Protectform', ignoreFields: ['__VIEWSTATE', '__VIEWSTATEGENERATOR', '__EVENTVALIDATION'], accountFormId: '210f6b6b-e0c2-422e-b3db-767307dbf9a1' } ]};

// (function (src) {

// var vvscript = document.createElement('script');

// vvscript.type = 'text/javascript';

// vvscript.async = true; vvscript.src = src;

// var scriptElements = document.getElementsByTagName('script');

// var lastScriptElement = scriptElements[scriptElements.length - 1];

// lastScriptElement.parentNode.insertBefore(vvscript, lastScriptElement.nextSibling);

// })(vvfc_ScriptURL);

// `,
//         }}
//       />
//     </Layout>
//   );
// };

// export default ProtectCloud;














////////////////////////// working on



import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { AiOutlineWarning } from "react-icons/ai";
import SEO from "../../components/seo.js";
import BusinessCrumbs from "../../components/Home/BusinessCrumbs.js";
import { useForm } from "react-hook-form";
import SvgAnimation from "../../components/SvgAnimate.js";
import { Container, Row, Col, Card } from "react-bootstrap";
import AdvancedSecurity from '../../images/protect-cloud/AdvancedSecurity.svg'
import AdvancedManagement from '../../images/protect-cloud/AdvancedManagement.svg'
import AdvancedData from '../../images/protect-cloud/AdvancedDataProtection.svg'
import AdvancedDisasterRecovery from '../../images/protect-cloud/AdvancedDisasterRecovery.svg'
import Microsoft365 from '../../images/protect-cloud/Microsoft365.svg'
import AdvancedEmailSecurity from '../../images/protect-cloud/AdvancedEmailSecurity.svg'
import '../../css/pages/cloudProtect.css'

const postData = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: true });
    }, 100);
  });
};

const ProtectCloud = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const [isOpen, setNav] = useState(true);
  const toggleTab = () => {
    setNav((isOpen) => !isOpen);
  };
  const onTop = () => {
    window.scroll(0, 0);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const [hideAlert, SetHideAlert] = useState(true);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const msgAlrt = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      SetHideAlert(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  const submitForm = async (data) => {
    console.log("Submission starting", data);
    const result = await postData(data);
    console.log("Submitting complete", result.success);
    setIsSuccessfullySubmitted(result.success);
    reset();
    console.log(data);
  };

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="Synectiks Secure Cloud Hosted Services supports a variety of workloads & Disaster Recovery with a high degree of control, security and simplicity. Support your mission-critical applications and workloads with instant and scalable infrastructure. With Synectiks Cloud Hosted Services, you can focus on your business needs instead of spending the time and money it takes to plan, procure, secure and manage the right infrastructure to meet your application demands."
        />
        <link
          rel="canonical"
          href="https://synectiks.com/protect-cloud/index.html"
        />
      </Helmet>
      <SEO title="Protect Cloud" />
      <div className="bg-lightgrey">
        <div className="d-block w-100 py-3 px-md-5">
          <div className="container-fluid">
            <BusinessCrumbs
              title="Home"
              subtitle="Business Continuity & Cyber Security"
              pageTitle="Protect Cloud"
            />
             <div className="d-flex flex-column flex-md-row mt-4">
               <div className="Pc-left pe-md-5 mb-3">
                 <h1 className="heading">Protect Cloud</h1>
                 <p className="p">
                   Protect Cloud is a SaaS based readily available Innovative and
                   powerful web-based ALL-IN-ONE solution, with one-agent on any
                   given Server/End-point device to serve all
                   {" "}
                   {/* <a href="/cloud-backup-service/index.html"> */}
                   Backup
                   {" "}
                   {/* </a>  */}
                   &
                   {" "}
                   {/* <a href="/cloud-disaster-recovery-service/index.html"> */}
                     Disaster Recovery{" "}
                   {/* </a> */}
                   needs along with basic necessary features like Anti-Virus,
                   Anti-Malware, Vulnerability Assessment, Patch management,
                   Remote Diagnostic assistance and URL-filtering. All devices in
                   your infrastructure are easily managed from a single web
                   console and well protected to meet all Business continuity,
                   Security, and compliance requirements.
                 </p>
                 <p className="p">
                   Maintain all the basic security and protection features with a
                   single solution, and avoid the hassle of purchasing software
                   from multiple vendors and installing multiple agents on the
                   same machine. Pick and choose the required license type for
                   each server/end-point device from the following, mix and match
                   the licenses as needed for each device in your environment.
                 </p>
                 <br/>
                 <p className="p">
                  Eliminate security breaches, downtime and data loss. Protect Cloud is the only solution that natively integrates cloud security, data protection and management to protect endpoints, systems and data. Integration and automation provide unmatched protection, making it simple to manage all the devices in your environment.
                  </p>
               </div>
               <div className="Pc-right w-50">
                 <p
                  id="req-font"
                  className="font-weight-bold text-center mt-3 mb-0"
                >
                  Request for a
                  <span className="text-black bold">&nbsp;Quick Demo</span>
                </p>
                <div>
                  <div className="text-black py-2 py-md-1">
                    <form
                      form="Protectform"
                      id="Protectform"
                      name="Protectform"
                      className="was-validated px-0"
                      action="#"
                      onSubmit={handleSubmit(submitForm)}
                    >
                      <div className="row d-flex flex-column">
                        <div className="text-left col field-group">
                          <label htmlFor="name">Your Name *</label>
                          <input
                            id="name"
                            maxlength="80"
                            size="20"
                            type="text"
                            {...register("first_name", {
                              required: true,
                              minLength: 2,
                              MaxLength: 80,
                            })}
                            disabled={isSubmitting || isSuccessfullySubmitted}
                          />
                          <div className="error">
                            {errors.first_name && (
                              <div className="d-flex align-items-baseline">
                                <AiOutlineWarning className="mr-2" />
                                <p>Enter Your Name</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-left col field-group">
                          <label for="email">Email *</label>
                          <input
                            id="email"
                            size="20"
                            type="text"
                            {...register("email", {
                              required: true,
                              pattern: {
                                value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                              },
                            })}
                            disabled={isSubmitting || isSuccessfullySubmitted}
                          />
                          <div className="errorml">
                            {errors.email && (
                              <div className="d-flex align-items-baseline">
                                <AiOutlineWarning className="mr-2" />
                                <p>Enter Your Email Id</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-center col field-group">
                          <input
                            type="submit"
                            name="submit"
                            value="Submit"
                            className="btn btn-primary w-50 pos-3 mx-auto"
                            id="submit-btn"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {isSuccessfullySubmitted && (
              <div
                ref={msgAlrt}
                id="msgAlert"
                className="w-100 alert alert-success"
                role="alert"
              >
                <div className="text-center">
                  <h1 className="alert-heading text-capitalize text-center">
                    We have received your request for demo
                  </h1>
                  <hr />
                  <h3 className="text-capitalize text-center">
                    we will get back <br /> to you soon !
                  </h3>
                </div>
              </div>
            )}
            <div className="mt-2 svg-animation w-60 m-auto">
              <SvgAnimation />
            </div>

            <div>
              <span
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '30px',
                  fontWeight: '700',
                  lineHeight: '48px',
                  textAlign: 'center',
                  borderRadius: '30px',
                  marginBottom: '10px',
                  display: 'block', // Ensure the span takes the full width
                  width: '100%', // Ensure the span takes the full width
                }}
              >
                Cyber protect Advanced packages breakdown, choose the package you need for your workload
              </span>

              <br />
              <br />
              <Container fluid>
  <Row className="card-row">
    {/* Card 1 */}
    <Col md={6} className="mb-4">
      <Card className="custom-card" style={{ borderRadius: '30px', height: '100%' }}>
        <Card.Body className="flex-card">
          <div className="custom-card-image">
            <img src={AdvancedSecurity} alt="Advanced Security Icon" />
          </div>
          <div className="custom-card-content">
            <Card.Title>Advanced Security Package:</Card.Title>
            <ul>
              <li>Advanced Security + EDR</li>
              <li>Gen AI-guided incident investigation, analysis, automated response</li>
              <li>Single-click response, including attack-specific rollback and recovery</li>
              <li>Next Generation Antivirus (NGAV)</li>
              <li>Anti-ransomware protection</li>
              <li>O-day and exploit protection</li>
              <li>URL filtering</li>
              <li>Anti-malware scans of backups</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Col>

    {/* Card 2 */}
    <Col md={6} className="mb-4">
      <Card className="custom-card" style={{ borderRadius: '30px', height: '100%' }}>
        <Card.Body className="flex-card">
          <div className="custom-card-image">
            <img src={AdvancedManagement} alt="Advanced Management Icon" />
          </div>
          <div className="custom-card-content">
            <Card.Title>Advanced Management Package:</Card.Title>
            <ul>
              <li>Vulnerability assessment (Windows/Mac/Linux along with third-party apps)</li>
              <li>Software & Hardware Inventory</li>
              <li>Automated patch management</li>
              <li>ML-based monitoring</li>
              <li>Software deployment</li>
              <li>AI-enabled remote scripting</li>
              <li>Automated pre-patch backup</li>
              <li>HDD health monitoring</li>
              <li>Next-gen remote desktop and assistance (with NEAR Protocol)</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>

  <Row className="card-row">
    {/* Card 3 */}
    <Col md={6} className="mb-4">
      <Card className="custom-card" style={{ borderRadius: '30px', height: '100%' }}>
        <Card.Body className="flex-card">
          <div className="custom-card-image">
            <img src={AdvancedData} alt="Advanced Data Protection Icon" />
          </div>
          <div className="custom-card-content">
            <Card.Title>Advanced Data Protection (Backup) Package:</Card.Title>
            <ul>
              <li>Backup of 25+ workload types (Local & cloud storage)</li>
              <li>Encryption and deduplication</li>
              <li>Ransomware-proof backup</li>
              <li>Group management Advanced Backup</li>
              <li>One-click mass recovery</li>
              <li>Continuous data protection</li>
              <li>Off-host validation, replication, and conversion to VMs</li>
              <li>Support for different types of application & database level backup</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Col>

    {/* Card 4 */}
    <Col md={6} className="mb-4">
      <Card className="custom-card" style={{ borderRadius: '30px', height: '100%' }}>
        <Card.Body className="flex-card">
          <div className="custom-card-image">
            <img src={AdvancedDisasterRecovery} alt="Advanced Disaster Recovery Icon" />
          </div>
          <div className="custom-card-content">
            <Card.Title>Advanced Data Protection (Disaster Recovery) Package:</Card.Title>
            <ul>
              <li>Cloud-only VPN connection</li>
              <li>Production and test failover to Acronis Cloud</li>
              <li>Orchestration with runbooks</li>
              <li>AI-assisted Automated test failover</li>
              <li>Fast Automated Failback with near-zero downtime</li>
              <li>Multiple connectivity options</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>

  <Row className="card-row">
    {/* Card 5 */}
    <Col md={6} className="mb-4">
      <Card className="custom-card" style={{ borderRadius: '30px', height: '100%' }}>
        <Card.Body className="flex-card">
          <div className="custom-card-image">
            <img src={Microsoft365} alt="Microsoft 365 Backup Icon" />
          </div>
          <div className="custom-card-content">
            <Card.Title>Microsoft 365 & Google Workspace Backup:</Card.Title>
            <ul>
              <li>Unlimited backup storage for Exchange Online, SharePoint Online, OneDrive, Teams, OneNote</li>
              <li>Unlimited backup storage Google Workspace</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Col>

    {/* Card 6 */}
    <Col md={6} className="mb-4">
      <Card className="custom-card" style={{ borderRadius: '30px', height: '100%' }}>
        <Card.Body className="flex-card">
          <div className="custom-card-image">
            <img src={AdvancedEmailSecurity} alt="Advanced Email Security Icon" />
          </div>
          <div className="custom-card-content">
            <Card.Title>Advanced Email Security:</Card.Title>
            <ul>
              <li>Phishing and Quishing prevention</li>
              <li>Business Email Compromise Prevention</li>
              <li>Malware protection</li>
              <li>APT and zero-day protection</li>
              <li>Account takeover detection</li>
              <li>Microsoft 365, Google Workspace, Exchange, and any SMTP supporting email service</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</Container>

            </div>
          </div>
        </div>
      </div>
      {/* <script
        dangerouslySetInnerHTML={{
          __html: `
            var vv_base_id = 'q00IB13hyn';
            var vv_ext_id = '5d53ed9a';
            var __ibaseUrl = (("https:" == document.location.protocol) ? "https://frontend.id-visitors.com" : "http://frontend.id-visitors.com");
            (function () {
              var va = document.createElement('script'); va.type = 'text/javascript'; va.async = true; va.src = __ibaseUrl + '/cscripts/' + vv_base_id + '-' + vv_ext_id + '.js'; var sv = document.getElementsByTagName('script')[0]; sv.parentNode.insertBefore(va, sv); })();
          `,
        }}
      /> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
           var vvfc_BaseURL = (("https:" == document.location.protocol) ? "https://forms.id-visitors.com/FrontEndWeb/" : "http://forms.id-visitors.com/FrontEndWeb/");
var vvfc_ServiceURL = vvfc_BaseURL + 'ProcessFormCapture.aspx';
var vvfc_ScriptURL = vvfc_BaseURL + 'Scripts/vvfcscript.js';
var trackedForms = ['Protectform'];
var options = { accountId: 'q00IB13hyn', serviceURL: vvfc_ServiceURL, pollInterval: 5000,
forms: [{ id: 'Protectform', ignoreFields: ['__VIEWSTATE', '__VIEWSTATEGENERATOR', '__EVENTVALIDATION'], accountFormId: 'eb891133-cd24-4365-8da3-66ccecb11a77' } ]};
(function (src) {
var vvscript = document.createElement('script');
vvscript.type = 'text/javascript';
vvscript.async = true; vvscript.src = src;
var scriptElements = document.getElementsByTagName('script');
var lastScriptElement = scriptElements[scriptElements.length - 1];
lastScriptElement.parentNode.insertBefore(vvscript, lastScriptElement.nextSibling);
})(vvfc_ScriptURL);
          `,
        }}
      />
    </Layout>
  );
};

export default ProtectCloud;
